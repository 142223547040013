<script setup>

import { ref } from "@vue/reactivity"
import { onMounted } from "vue"

const reload = ref(false)
const reloadMenu = ref(false)

onMounted(AnimateHome)

function AnimateHome() {
  const divHome = document.getElementById('div-home-animation')
  const outerCirc = document.getElementById('div-circ-wrapper')

  outerCirc.style.backgroundImage = `url(${localStorage.getItem('homeAnimationImageData')})`

  divHome.style.zIndex = '99'
  divHome.style.opacity = 1

  outerCirc.style.width = '120px'
  outerCirc.style.height = '120px'
  outerCirc.style.padding = '5px'
  outerCirc.style.opacity = 1

  setTimeout(() => {
    outerCirc.style.width = `${outerCirc.clientWidth + window.screen.width * 2}px`
    outerCirc.style.height = `${outerCirc.clientHeight + window.screen.width * 2}px`
    outerCirc.style.padding = '80px'
    outerCirc.style.opacity = 0

    divHome.style.opacity = 0
    divHome.ontransitionend = () => {
      divHome.style.zIndex = '-10'
      divHome.remove()
    }
  }, 2000)
}

</script>


<template>
  <div id="div-home-animation">
    <div id="div-circ-wrapper">
    </div>
  </div>

  <div class="div-master div-home-background" id="div-master">
    <div class="div-content">
      <div>
        <router-view :key="reload" @force-reload="reloadMenu = !reloadMenu" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.div-home-background {
  margin: 0 auto;
  width: 100%;
  margin: 0;
  height: 100vh;
  background-color: black;
  position: relative;
  font-size: .8em;
}


#div-home-animation {
  position: absolute;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: white;
  transition: opacity 1s ease-out 0.7s;
  display: flex;
  align-items: center;
  overflow: hidden !important;
}

#div-circ-wrapper {
  margin: 0 auto;
  background-image: url('../images/logo_hub.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 120px;
  transition: width 2s ease-in, height 2s ease-in, padding 2s ease-in, opacity 1.5s ease-out;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.div-master {
  height: 100vh;
  display: flex;
}

.div-content {
  z-index: 0;
  overflow: hidden;
  height: calc(100% - 7rem);
}
</style>
