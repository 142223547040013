<template>
  <div class="app-container">
    <div v-if="CleanLayout()" :class="CleanOrLogin()">
      <router-view />
    </div>

    <div v-else>
      <Master />
    </div>

    <div v-if="CleanLayout() && !this.$route.meta.loginLayout" class="div-app-footer"></div>
    <div v-else-if="!this.$route.meta.loginLayout" class="div-master-footer">
      <div class="div-master-footer-marquee">&nbsp;</div>
      <div class="div-master-footer-marquee">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import Master from "./views/Master";
import { HUB } from "./hub";

export default {
  name: "App",
  props: {},
  components: {
    Master,
  },
  data() {
    return {
      lastCookie: "",
    };
  },
  methods: {
    CleanLayout() {
      return this.$route.meta.cleanLayout === true ? true : false;
    },

    CleanOrLogin() {
      return this.$route.meta.loginLayout === true ? "" : "clean";
    },
  },
  mounted() {
    HUB.CheckCredentials();
  },
};
</script>

<style>
.div-master-footer,
.div-app-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background: linear-gradient(270deg, rgb(205 176 255) 10%, rgb(135 255 165) 30%, rgb(24 232 255) 80%, rgb(69 77 255) 100%);
  z-index: 98;
  height: 2%;
}

.div-master-footer {
	display: flex;
	overflow: hidden;
	margin-left: -100%;
	width: 200%;
}
		
.div-master-footer-marquee {
	animation-duration: 12s;
	animation-iteration-count: infinite;
	animation-name: marquee-content;
	animation-timing-function: linear;
	background: linear-gradient(to right, #87ffa5, #18e8ff, #454dff, #cdb0ff, #87ffa5);
	width: 200%;
}
		
@keyframes marquee-content {
	from { 
		transform: translateX( 0% );	
	}
	to {	
		transform: translateX( 99.5% );	
	}
}

</style>

<style scoped>
.app-container {
  width: 100%;
  min-height: 100vh;
}
</style>
