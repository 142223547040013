<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import { Gems } from '@/gems'

const emits = defineEmits(['import'])
const props = defineProps(['tableName'])
const label = ref(null)
const placeholder = ref(null)
const btnImport = ref()

function OpenFileSelect() {
    document.getElementById(props.tableName).click()
}

function SetPlaceholder() {
  placeholder.value = document.getElementById(props.tableName).files[0].name ?? null
  btnImport.value.classList.remove('blink-import')
  btnImport.value.classList.add('blink-import')
}

function Import() {
  btnImport.value.classList.remove('blink-import')
  emits('import', props.tableName)
  placeholder.value = null
}

onMounted(() => {
  if(label.value)
    Gems.Tooltip(label.value, "Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]", "tooltip")
})
</script>

<template>
    <input type="text" ref="label" class="inputfile clickable" @click="OpenFileSelect()"
        :placeholder="placeholder ?? 'Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]'"
        readonly />
    <input type="file" class="hidden-input" :id="props.tableName"  @change="SetPlaceholder()" />


    <div class="inputs-border div-btn-import">
      <button ref="btnImport" class="btn-import clickable" @click="Import">IMPORTAR <span class="arrow">&#8593;</span></button>
    </div>
</template>

<style scoped>

.inputfile {
  width: -webkit-fill-available;
  color: initial;
}

.inputfile,
.btn-import {
  text-align: center;
  padding: 12px;
  border-radius: 7px;
  font-weight: 300;
  font-family: 'Space Grotesk', sans-serif;  
  border: none;
}

.btn-import {
  background-color: black;
}

/* .inputfile:hover {
  color: var(--default-hover-color) !important;
}

.inputfile::placeholder {
  color: var(--default-app-color);
}  */

.hidden-input {
  display: none;
}

.div-inputs {
  flex-grow: 1;
}

.div-btn-import {
  margin-left: 5px;
  margin-bottom: 0px;
}

.btn-import {
  width: 232px;
}

.btn-import:hover {
  color: var(--default-hover-color) !important;
}

</style>
