<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps(['activateModal', 'releasesArr'])
const emits = defineEmits(['update-releases', 'close-modal'])

function ConfirmReleases() {
  let appMaxReleases = []

  props.releasesArr.map((release, i) => {
    if(i == 0 || props.releasesArr[i-1]['app_id'] != release.app_id)
      appMaxReleases.push({app_id: release.app_id, max_release: release.max_release})
  })

  emits("update-releases", appMaxReleases)
}

</script>

<template>
    <div class="div-modal" v-show="props.activateModal">
      <div class="div-modal-content">
        <h2 class="modal-title">Atualizações mais recentes</h2>
        <div class="releases-list">
          <div v-for="(release, i) in releasesArr" :key="i">
            <div v-if="i == 0 || releasesArr[i-1]['app_id'] != release.app_id" class="div-app-name">
              {{ release.app_name }}
            </div>

            <div class="release-item">
              <div class="release-date">
                <span class="release-item-date">{{ release.release_date }}</span>
                <ul class="ul-item">
                  <li class="li-item"></li>
                </ul>
              </div>
              <div class="release-item-text">
                <span class="release-item-title">{{ release.title }}</span>
                <span class="release-item-description">{{ release.description }}</span>
              </div>
            </div>
          </div>
        </div>
  
        <div class="release-btns">
          <div class="inputs-border">
            <button class="button clickable" @click="emits('close-modal')">
              VER MAIS TARDE
            </button>
          </div>

          <div class="inputs-border">
            <button class="button clickable" @click="ConfirmReleases()">
              TOMEI CONHECIMENTO
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>

  
<style scoped>

  .div-modal::before {
    content: "";
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;    
  }
  
  .div-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    background: #ffffff11;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 99;
  }
  
  .div-modal-content {
    width: 50%;
    margin: 4% auto;
    background: #000000cc;
    padding: 16px;
    font-size: 0.9vw;
    border-radius: 15px;
  }

  .div-app-name {
    background-color: rgb(69 77 255);
    border-radius: 10px;
    padding: 2px 2px 2px 10px;
    margin-bottom: 20px;
  }
  
  .release-item-title,
  .release-item-date {
    display: block;
    font-weight: bold;
  }
  
  .release-item-title {
    margin-bottom: 9px;
  }
  
  .releases-list {
    padding: 20px;
  }
  
  .modal-title {
    margin: 0 0 10px 0;
    color: rgb(135 255 165);
  }
  
  .release-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 5px;
}

  .inputs-border {
    min-width: 30%;
  }

  .button {
    height: 100%;
  }

  .releases-list > :not(:first-child) {
    margin-top: 5%;
  }

  .release-item {
    display: inline-grid;
    grid-template-columns: fit-content(100%) fit-content(100%);
    grid-gap: 15px;
  }

  .release-item-description {
    word-break: break-word;
    margin-top: 5px;
  }

  .ul-item {
    float: right;
    margin: 2px -12px 0px 0px;
  }

  .li-item {
    font-size: 1.5em;
  }

</style>
  